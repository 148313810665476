@import url("https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar Animation */
@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/* Add the animation class to transition between states */
.transform {
    animation-fill-mode: both;
}

.sidebar {
    transition: transform 0.3s ease-in-out;
}


/* Optional: Add media query for smaller screens */
@media (max-width: 700px) {

    /* .transform {
        transform: translateX(-100%) !important;
    } */
    .sidebar {
        position: absolute;
        width: 250px;
        /* left: -20px; */
    }
}


.sidebarHR {
    display: flex;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, rgb(224, 225, 226) 49.52%, rgba(224, 225, 226, 0) 100%);
    margin-top: 20px;
    margin-bottom: 20px;
}


body {
    background-color: #E2E2E2;
    font-family: "Poppins", "sans-serif";
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


:-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 6px;
}

.scrollbarHide::-webkit-scrollbar {
    display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.bgImg {
    background-image: url("./assets/images/bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.loginBg {
    background-image: url("./assets/images/LoginImage.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
}

.input-time {
    background: transparent;
    color: #ffff;
    border: 1px solid #ffff;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: rgb(196, 196, 196);
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #015377; */
    border: 1px solid rgb(47, 56, 103);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #0075FF;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #0075FF !important;
    /* border: 1px solid #0075FF; */
}

/* input:focus+.slider {
     box-shadow: 0 0 1px #2196F3;
 } */
input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 15px;
    background: #CBD5E0;
    border: 1px solid #CBD5E0;
}

.slider.round:before {
    border-radius: 50%;
    /* -color: #FC472B; */
}

.adCard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    /* padding: 20px; */
}

@media (max-width: 1200px) {
    .adCard {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.kioskThemeGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}